/* eslint-disable @next/next/no-img-element */

import { cn, Tooltip } from '@portalform/design-system';
import { InfoCircledIcon } from '@radix-ui/react-icons';
import assert from 'assert';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { FormEvent, useState } from 'react';
import toast from 'react-hot-toast';
import pckg from '../../package.json';
import { twMerge } from 'tailwind-merge';

export const ROUTE_LOGIN = '/';

function isValidDomain(domain: string) {
	return !!domain.match(/^(https?:\/\/)?[a-z0-9][a-z0-9-_]*\.myshopify\.com$/i);
}

function MissingBrandId() {
	return (
		<div className="w-[500px] p-3 bg-red-100 dark:bg-red-950 rounded-md text-sm">
			<strong>Error.</strong> Missing brand_id parameter in the URL.{' '}
		</div>
	);
}

const DEFAULT_DOMAIN =
	process.env.NODE_ENV === 'development' ? 'hahvaleu.myshopify.com' : '';

const Page: NextPage = () => {
	const [domain, setDomain] = useState(DEFAULT_DOMAIN);
	const [isValid, setIsValid] = useState(true);
	const router = useRouter();

	const onBlur = (e: FormEvent<HTMLInputElement>) => {
		setIsValid(domain ? isValidDomain(domain) : true);
	};

	const brandId = (router.query.brand_id || router.query.brandId) as string;

	async function onClickLogin(e: FormEvent) {
		e.preventDefault();

		if (!brandId) {
			toast.error('Missing brandId parameter in the URL.');
			return;
		}

		if (!isValidDomain(domain)) {
			toast.error('Enter a valid domain ending with .myshopify.com');
			return;
		}

		e.preventDefault();
		startShopifyOAuth(domain, brandId);
	}

	return (
		<div className="w-full h-screen flex justify-center items-center flex-col gap-2">
			{router.isReady && !brandId && <MissingBrandId />}
			<div className="flex w-[500px] max-w-full flex-col p-12 bg-foreground gap-5 rounded-xl border-[1px] border-zinc-100 dark:border-zinc-800">
				<header>
					<div className="flex  justify-center pb-10">
						<div className="bg-zinc-100 w-[80px] h-[80px] rounded-full flex items-center justify-center">
							<img alt="" width="40px" src="/overjoy.svg" />
						</div>
						<div className="bg-zinc-200 w-[80px] h-[80px] rounded-full flex items-center justify-center ml-[-20px]">
							<img alt="" width="33px" src="/shopify.png" />
						</div>
					</div>
					<h1 className="text-xl">Connect your Shopify store to Overjoy</h1>
				</header>

				<form onSubmit={onClickLogin} className="flex flex-col gap-4">
					<h2 className="flex flex-row">
						What is your&nbsp;<abbr>myShopify</abbr>&nbsp;domain?{' '}
						<Tooltip content="Every store on Shopify has a canonical URL of the format example.myshopify.com, which is used to uniquely identify it.">
							<InfoCircledIcon className="inline-block h-4 w-4 ml-1 mt-[-2px]" />
						</Tooltip>
					</h2>
					<input
						type="text"
						value={domain}
						onChange={(e) => setDomain(e.target.value)}
						onBlur={onBlur}
						placeholder="example.myshopify.com"
						className={cn(
							'bg-background focus:outline-none focus:shadow-outline border border-zinc-300 dark:border-zinc-700 rounded-md py-2 px-4 block w-full appearance-none leading-normal',
							!isValid && 'border-red-300'
						)}
					/>
					<button
						className={twMerge(
							'transition-all  py-3 px-4 rounded',
							'text-md font-semibold',
							'text-white bg-black dark:bg-teal-800',
							'disabled:opacity-50 hover:opacity-90'
						)}
						disabled={!brandId}
					>
						Authorize on Shopify
					</button>
				</form>
				<footer className="flex flex-col items-center text-sm opacity-30">
					production &bull; v{pckg.version}
				</footer>
			</div>
		</div>
	);
};

export default Page;

const GENERIC_ERROR_MESSAGE =
	'Failed to talk to Shopify. Our team has been notified. You can try again or contact us at jun@Overjoy.shop';

// Begin the OAuth flow for a user of the passed-in shop.
async function startShopifyOAuth(shop: string, brandId: string) {
	assert(brandId);

	shop = shop.replace(/https?:\/\//, '');

	const response = await fetch(`/api/shopify/auth`, {
		method: 'POST',
		body: JSON.stringify({
			shop,
			brandId,
			next: '/app/login',
		}),
		headers: {
			'Content-Type': 'application/json',
		},
	});

	let body;
	try {
		body = await response.json();
	} catch (e) {
		toast.error(GENERIC_ERROR_MESSAGE);
		return;
	}

	if (response.status !== 200) {
		toast.error(body.message || GENERIC_ERROR_MESSAGE);
		return;
	}

	toast('Taking you to Shopify...');

	if (body.redirectTo) {
		if (window.parent) {
			window.parent.location.href = body.redirectTo;
		} else {
			window.location.href = body.redirectTo;
		}
	}
}
